import ENV from './env.js';

let API_URL = '';

if (ENV === 'local') {
  API_URL = 'https://megacampus.empat.tech/api';
} else if (ENV === 'production') {
  API_URL = 'https://megacampus.app/api';
}

export default API_URL;
