import axios from 'axios';
import API_URL from '../../config/apiUrl.js';

let token = document.getElementById('app-token')?.value;

const ApiClient = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

ApiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log({ response });

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log({ error });

    //for overall app redirect for non-authorized users
    if (error.response.status === 401) {
      document.location.href = 'https://megacampus.com/';
    }

    // alert(error);

    return Promise.reject(error);
  },
);

export { ApiClient };
